import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="background-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="mb-3">Privacy Policy</h2>
            <div>
              <p className="f-16 fw-bold">1. Introduction</p>
              <p className="f-16 fw-normal">
                Welcome to{" "}
                <Link to="/" className="text-danger">
                  Pasoo Game
                </Link>
                . This Privacy Policy is designed to help you understand how we
                collect, use, disclose, and safeguard your personal information.
                By using our Site, you agree to the terms outlined in this
                Privacy Policy.
              </p>
            </div>
            <div>
              <p className="f-16 fw-bold">2. Information We Collect</p>
              <p className="f-16 fw-normal">
                We may collect both personally identifiable information and
                non-personally identifiable information from you. The types of
                information we may collect include:
              </p>
              <div className="ms-3">
                <p className="f-16 fw-bold">2.1 Personal Information:</p>
                <ul>
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Contact information</li>
                </ul>
                <p className="f-16 fw-bold">2.2 Non-Personal Information:</p>
                <ul>
                  <li>Browser type</li>
                  <li>IP address</li>
                  <li>Device information</li>
                  <li>Usage data</li>
                </ul>
              </div>
            </div>
            <div>
              <p className="f-16 fw-bold">3. How We Collect Information</p>
              <p className="f-16 fw-normal">
                We collect information in the following ways:
              </p>
              <div className="ms-3">
                <p className="f-16 fw-bold">3.1 Directly from You:</p>
                <ul>
                  <li>
                    When you provide information through forms on our Site
                  </li>
                  <li>
                    When you communicate with us via email or other channels
                  </li>
                </ul>
                <p className="f-16 fw-bold">3.2 Automatically:</p>
                <ul>
                  <li>Through cookies and other tracking technologies</li>
                </ul>
              </div>
            </div>
            <div>
              <p className="f-16 fw-bold">4. How We Use Your Information</p>
              <p className="f-16 fw-normal">
                We use the information we collect for various purposes,
                including:
              </p>
              <div className="ms-3">
                <p className="f-16 fw-bold">
                  4.1 To provide and maintain our Site.
                </p>
                <p className="f-16 fw-bold">
                  4.2 To personalize your experience on the Site.
                </p>
                <p className="f-16 fw-bold">
                  4.3 To send you newsletters, updates, and marketing
                  communications.
                </p>
                <p className="f-16 fw-bold">
                  4.4 To respond to your inquiries and provide customer support.
                </p>
                <p className="f-16 fw-bold">
                  4.5 To monitor and analyze usage patterns and improve our
                  Site.
                </p>
              </div>
            </div>
            <div>
              <p className="f-16 fw-bold">5. Sharing Your Information</p>
              <p className="f-16 fw-normal">
                We may share your information with third parties in the
                following circumstances:
              </p>
              <div className="ms-3">
                <p className="f-16 fw-bold">5.1 With your consent.</p>
                <p className="f-16 fw-bold">
                  5.2 To comply with legal obligations.
                </p>
                <p className="f-16 fw-bold">
                  5.3 To protect and defend our rights and property.
                </p>
              </div>
            </div>
            <div>
              <p className="f-16 fw-bold">6. Security of Your Information</p>
              <p className="f-16 fw-normal">
                We take reasonable measures to protect the security of your
                information. However, no method of transmission over the
                internet or electronic storage is completely secure.
              </p>
            </div>
            <div>
              <p className="f-16 fw-bold">7. Your Choices</p>
              <p className="f-16 fw-normal">
                You can choose not to provide certain information, but it may
                limit your ability to use certain features of the Site.
              </p>
            </div>
            <div>
              <p className="f-16 fw-bold">8. Changes to this Privacy Policy</p>
              <p className="f-16 fw-normal">
                We reserve the right to update this Privacy Policy at any time.
                Any changes will be effective immediately upon posting on the
                Site.
              </p>
            </div>
            <div>
              <p className="f-16 fw-bold">9. Google AdSense:</p>
              <ul>
                <li className="f-16 fw-bold">
                  9.1 Third-Party Advertisements:
                </li>
                <p className="f-16 fw-normal">
                  Our website may use Google AdSense, a third-party advertising
                  service provided by Google Inc. Google AdSense uses cookies to
                  serve ads based on your visits to this site and other sites on
                  the Internet. These cookies enable Google and its partners to
                  display personalized ads based on your browsing history and
                  interests. You can learn more about Google’s advertising
                  policies and how to opt-out of personalized advertising by
                  visiting the{" "}
                  <Link to="/" className="text-danger">
                    Google Ad Settings
                  </Link>
                  .
                </p>
                <li className="f-16 fw-bold">9.2 DoubleClick Cookie:</li>
                <p className="f-16 fw-normal">
                  Google, as a third-party vendor, uses cookies to serve ads on
                  our site. The DoubleClick cookie enables Google to serve ads
                  to users based on their visit to our site and other sites on
                  the Internet. Users may opt-out of the use of the DoubleClick
                  cookie for interest-based advertising by visiting{" "}
                  <Link to="/" className="text-danger">
                    Google Ad Settings
                  </Link>
                  .
                </p>
                <li className="f-16 fw-bold">
                  9.3 Information Collected by Google AdSense:
                </li>
                <p className="f-16 fw-normal">
                  Google AdSense may collect information such as your IP
                  address, browser type, and the date and time of your visit to
                  provide personalized advertisements. This information is used
                  in accordance with Google’s privacy policy, which can be found
                  at{" "}
                  <Link href="/" className="text-danger">
                    Google Privacy & Terms
                  </Link>
                  .
                </p>
                <li className="f-16 fw-bold">
                  9.4 Opting Out of Ad Personalization:
                </li>
                <p className="f-16 fw-normal">
                  You can opt-out of personalized advertising by visiting{" "}
                  <Link to="/" className="text-danger">
                    Google Ad Settings
                  </Link>{" "}
                  and adjusting your preferences. Additionally, you can opt-out
                  of third-party vendor’s use of cookies for personalized
                  advertising by visiting www.aboutads.info or
                  www.networkadvertising.org.
                </p>
                <li className="f-16 fw-bold">9.5 Changes to Google AdSense:</li>
                <p className="f-16 fw-normal">
                  We do not have control over changes made by Google AdSense or
                  other third-party advertising services. Changes to their
                  policies and practices are subject to their respective terms
                  and conditions.Ensure that you review and update this section
                  regularly to reflect any changes in your use of Google AdSense
                  or any updates to their policies. Again, it’s advisable to
                  seek legal advice to ensure that your privacy policy complies
                  with applicable laws and regulations.
                </p>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">10. Contact Us</p>
              <p className="f-16 fw-normal">
                We are using open-source games on our website. If you see your
                games on our website and you want not to give permission for
                using them, you can contact us.
              </p>
              <p className="f-16 fw-normal">
                If you have any questions about this Privacy Policy, please
                contact us at{" "}
                <Link to="/" className="text-danger fw-bold">
                  pasoo.game+contact@gmail.com
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
