import React, { useEffect, useState } from "react";
import Logo from "../../Assets/icon/logo.png";
import { Link, useLocation } from "react-router-dom";
import "../../Assets/style/nav.css";

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const gameCanvas = location.pathname === "/game-canvas";
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname.substr(1)); // Extract path without leading '/'
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && !event.target.closest(".nav-container")) {
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <header>
        <div className="nav-container">
          <input
            type="checkbox"
            name="check"
            id="check"
            checked={isMobileMenuOpen}
            onChange={toggleMobileMenu}
            className="check-input"
          />
          <label htmlFor="check" className="close-icon"></label>{" "}
          <div className="logo-container">
            <Link className="navbar-brand" to="/">
              <img src={Logo} height={"60px"} alt="logo not founded" />
            </Link>
          </div>
          <div className="nav-btn" id="menu-container">
            <div className="nav-links">
              <ul>
                <li className="nav-link" style={{ "--i": ".3s" }}>
                  <Link
                    className={`nav-link ${
                      activeLink === "" || activeLink === "home"
                        ? "text-warning"
                        : "text-light"
                    }`}
                    to="/"
                    onClick={toggleMobileMenu}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": ".4s" }}>
                  <Link
                    className={`nav-link ${
                      activeLink === "game-rules"
                        ? "text-warning"
                        : "text-light"
                    }`}
                    to="/game-rules"
                    onClick={toggleMobileMenu}
                  >
                    Game Rules
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": " .5s" }}>
                  <Link
                    className={`nav-link ${
                      activeLink === "blog" ? "text-warning" : "text-light"
                    }`}
                    to="/blog"
                    onClick={toggleMobileMenu}
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": " .6s" }}>
                  <Link
                    className={`nav-link ${
                      activeLink === "contact-us"
                        ? "text-warning"
                        : "text-light"
                    }`}
                    to="/contact-us"
                    onClick={toggleMobileMenu}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": " .7s" }}>
                  <Link
                    className={`nav-link ${
                      activeLink === "privacy-policy"
                        ? "text-warning"
                        : "text-light"
                    }`}
                    to="/privacy-policy"
                    onClick={toggleMobileMenu}
                  >
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="hamburger-menu-container">
            <div className="hamburger-menu">
              <div></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
