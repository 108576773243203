import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const GameFrame = ({}) => {
  const [gameUrl, setGameUrl] = useState("");

  useEffect(() => {
    const storedGame = localStorage.getItem("selectedGame");
    if (storedGame) {
      setGameUrl(storedGame);
    }
  }, []);

  const handleClose = () => {
    localStorage.removeItem("selectedGame");
    window.location.href = "/";
  };

  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <div
          style={{
            width: "400px",
          }}
        >
          {" "}
          <div
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              cursor: "pointer",
              zIndex: 10000,
              backgroundColor: "white",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <iframe
            src={gameUrl}
            title="Game Frame"
            width="100%"
            style={{
              border: "none",
              height: "85vh",
              backgroundColor: "black",
            }}
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default GameFrame;
