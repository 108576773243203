import React from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../Assets/icon/logo.png";

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  console.log("location.pathname", location.pathname);
  const gameCanvas = location.pathname === "/games";
  const contactUs = location.pathname === "/contact-us";
  return (
    <>
      {!gameCanvas && (
        <section
          className="footer_section"
          style={
            {
              // position: contactUs ? "fixed" : "",
              // bottom: contactUs ? 0 : "",
              // width: "100%",
            }
          }
        >
          <div className="container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                padding: "20px 0px",
              }}
            >
              <div className="row justify-content-center">
                <div className="col-12 text-center m-0">
                  <Link to="/">
                    <img
                      src={Logo}
                      height={"120px"}
                      alt="logo not founded"
                      style={{ marginBottom: "20px", height: "50px" }}
                    />
                  </Link>
                  <h6 className="text-white f-16 mb-4">
                    By selecting any of the option, you agree to our
                    <Link to="/privacy-policy" className="text-warning">
                      {" "}
                      privacy policy
                    </Link>
                    .
                  </h6>
                  <h6 className="f-16 mb-2">
                    <Link to="/contact-us" className="text-warning">
                      Contact Us{" "}
                    </Link>{" "}
                    <Link to="/game-rules" className="text-warning">
                      {"  "}| Game Rules
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Footer;
